export default class CampaignFaq {

	constructor () {
		this.Order = 0;
		this.id = 0;
		this.campaignId = 0;
		this.question = '';
		this.answer = '';
	}

}