<template>
  <div>
    <v-layout>
      <div class="boxapp">
        <!-- boxapp head -->
        <div class="boxapp-head">
          <a href="#" @click="$router.go(-1)">
            <faIcon type="fal" name="long-arrow-left" size="24" colorFont="#cfd2dd" />
          </a>
          <h1>{{ project.type }}</h1>
        </div>

        <!-- boxapp body -->
        <div class="box-main">
          <div class="section">
            <!-- section head -->
            <v-layout>
              <div class="head w-100 mt-0">
                <h4>Perguntas e respostas</h4>
              </div>
              <div>
                <v-btn
                  flat
                  round
                  color="white"
                  class="btn btn-primary px-4"
                  large
                  @click="create"
                  :disabled="disableNewFaq"
                >
                  <i class="mr-2 fas fa-plus"></i>Nova Pergunta
                </v-btn>
              </div>
            </v-layout>

            <div class="wrapper pt-3 mw-total">
              <!-- pergunta -->
              <div class="mb-4" v-for="(faq, index) in faqList" :key="index">
                <h5>#{{ index + 1 }}</h5>
                <div class="icon-tools">
                  <a class="fal fa-trash" @click="removeFaq(faq)"></a>
                </div>
                <a href></a>
                <InputText
                  type="text"
                  labelAreaCharLimit="360"
                  place-holder="Escreva a pergunta"
                  v-model="faq.question"
                  labelCharLimit="250"
                />
                <InputTextArea
                  rows="4"
                  place-holder="Escreva a resposta aqui"
                  v-model="faq.answer"
                  labelAreaCharLimit="1000"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="box-help-spacer"></div>
      <HelpBox :text="helpBoxText" />
    </v-layout>
    <div class="footer-step">
      <v-btn
        flat
        round
        color="white"
        class="btn-primary flex-inherit px-5 ml-auto"
        large
        @click="save"
      >Salvar</v-btn>
    </div>
    <AucAlert
      ref="removeCampaignFaqAlert"
      title="Você tem certeza que deseja remover essa pergunta da lista?"
      confirmButtonText="Sim"
      cancelButtonText="Não"
      @confirm="confirmRemoveFaq"
    />
  </div>
</template>

<script type="plain/text">
import FooterStep from "@/components/FooterStep.vue";
import AucAlert from "@/components/AucAlert.vue";
import HelpBox from "@/components/HelpBox.vue";
import { HelpBoxText } from "@/scripts/models/enums/helpBoxText.enum.js";
import { GetCurrentOrder } from "@/scripts/helpers/list.helper";
import { Routes } from "@/scripts/models/enums/routes.enum.js";
import CampaignFaq from "@/scripts/models/campaignFaq.model";
import CampaignService from "@/scripts/services/campaign.service";

export default {
  components: {
    AucAlert,
    HelpBox,
    FooterStep
  },
  props: ["pageOptions", "campaignId"],
  data() {
    return {
      campaignService: new CampaignService(),
      faqList: [],
      selectedFaq: 0,
      helpBoxText: HelpBoxText.CampaignFaq,
      project: {
        type: "Cadastro FAQ"
      }
    };
  },
  created() {
    this.pageOptions.changeClass("footer");
    this.listByCampaignId();
  },
  computed: {
    disableNewFaq: function() {
      return (
        this.faqList.length != 0 &&
        (this.faqList[this.faqList.length - 1].question == "" ||
          this.faqList[this.faqList.length - 1].answer == "")
      );
    }
  },
  methods: {
    listByCampaignId() {
      this.campaignService.listFaqByCampaignId(
        this.listFaqByCampaignIdCallback,
        this.$route.query.campaignId
      );
    },
    listFaqByCampaignIdCallback(data) {
      this.faqList = data;
    },
    create() {
      if (
        this.faqList.length == 0 ||
        (this.faqList[this.faqList.length - 1].question != "" ||
          this.faqList[this.faqList.length - 1].answer != "")
      ) {
        let campaignFaq = new CampaignFaq();
        campaignFaq.Order = GetCurrentOrder(this.faqList);
        campaignFaq.campaignId = this.$route.query.campaignId;
        this.faqList.push(campaignFaq);
      } else {
        this.$store.commit(
          "SET_SNACKBAR_MESSAGE",
          "Preencha os todos os campos primeiro antes de adicionar outro"
        );
      }
    },
    save() {
      this.campaignService.saveFaq(this.saveCallback, this.faqList);
    },
    saveCallback(data) {
      this.$router.push({
        name: Routes.app.CampaignList
      });
    },
    removeFaq(faq) {
      this.selectedFaq = faq;
      this.$refs.removeCampaignFaqAlert.open();
    },
    confirmRemoveFaq() {
      if (this.selectedFaq.id == 0) {
        var index = this.faqList.findIndex(
          x => x.Order == this.selectedFaq.Order
        );
        this.faqList.splice(index, 1);
      } else {
        var index = this.faqList.findIndex(x => x.id == this.selectedFaq.id);
        this.faqList.splice(index, 1);
        if (this.selectedFaq.id > 0)
          this.campaignService.deleteFaq(null, this.selectedFaq.id);
      }
    }
  }
};
</script>